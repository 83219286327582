<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <v-card class="border-radius-xl card-shadow">
            <div class="px-4 py-4 mt-2">
             <h2>kjkdjfksdjf</h2>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  components: {
  },
  data: () => ({
    selectedTab: null,
    hhh: 400,
    items: [
      
    ],
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
     ...sync("*")
  },
  created() {
    this.items.push({
        name: "Xандалтын линкүүд",
        children: [
          {
            name: "Xандалтын линкүүд",
            fbpath: this.userData.ppschool+"/xAccessLinks",
          },
        ],
      },)
  },

  methods: {
  },
};
</script>
